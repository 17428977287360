'use strict';

import { combineReducers } from "redux";

import { application } from 'portal/react-application/reducer';
import { modal } from 'portal/common/components/modal/reducer';
import { publicRoute } from 'portal/guest/reducer';
import { guideRoute } from 'portal/guide/reducer';
import { amtKvm } from 'portal/remote-control-devices/components/amt-redux-reducer';
import { amtOperationsStatus } from 'portal/device/components/amt-operations-redux-reducer';

export default combineReducers({
  application,
  modal,
  publicRoute,
  guideRoute,
  amtKvm,
  amtOperationsStatus,
});
