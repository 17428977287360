'use strict';

import { handleActions } from 'redux-actions';
import {
  amtOperationsStatus as amtOperationsStatusAction,
} from './amt-operations-redux-actions';

const initialState = {
  source: null,
  data:   null,
  error:  null,
};

export const amtOperationsStatus = handleActions({
  [amtOperationsStatusAction] (prevState, action = {}) {
    const { payload: { source = null, data = null, error = null } = {} } = action;
    return {
      ...prevState,
      source,
      data,
      error,
    };
  },
}, initialState);
